import dayjs from 'dayjs';

import { User } from 'firebase/auth';
import * as Firestore from 'firebase/firestore';


export interface FirestoreModel {
  readonly id?: string;
}

export type Quality = 1 | 2 | 3 | 4 | 5;

export interface Category extends FirestoreModel {
  category: string;
}

export interface Chunk extends FirestoreModel {
  readonly category: Category;
  readonly inTime: dayjs.Dayjs;
  readonly outTime: dayjs.Dayjs;
  readonly name: string;
}

export interface CurrentTask extends FirestoreModel {
  readonly category: Category;
  readonly inTime: dayjs.Dayjs;
  readonly outTime?: dayjs.Dayjs;
  readonly name: string;
}

export function converter<T extends FirestoreModel>(): Firestore.FirestoreDataConverter<T> {
  return {
    toFirestore: ({id, ...data}: T) => {
      /* omit id */
      const serialize = {...data};
      return serialize;
    },
    fromFirestore: (snap: Firestore.QueryDocumentSnapshot) =>
      ({
        ...snap.data(),
        id: snap.id,
      } as T),
  };
}

function makeChunksConverter  <
  T extends FirestoreModel & {inTime: dayjs.Dayjs; outTime?: dayjs.Dayjs}
>(): Firestore.FirestoreDataConverter<T> {
  return {
    toFirestore: ({id, ...data}: T) => {
      const serialize = {
        ...data,
        inTime: Firestore.Timestamp.fromDate(data.inTime.toDate()),
        outTime: data.outTime ? Firestore.Timestamp.fromDate(data.outTime.toDate()) : undefined,
      };
      /* omit id */
      return serialize;
    },
    fromFirestore: (snap: Firestore.QueryDocumentSnapshot) => {
      const data = snap.data();

      return {
        ...data,
        inTime: data.inTime ? dayjs((data.inTime as Firestore.Timestamp).toDate()) : undefined,
        outTime: data.outTime ? dayjs((data.outTime as Firestore.Timestamp).toDate()) : undefined,
        id: snap.id,
      } as T;
    },
  };
}

// It's important for these objects to be memoized across renders due to a reactfire query equality check
const chunksConverter = makeChunksConverter<Chunk>()
const categoryConverter = converter<Category>()
const currentTaskConverter = makeChunksConverter<CurrentTask>()

export function querySnapshotToArray<T>(docs: Firestore.QuerySnapshot<T> | null | undefined): T[] {
  return docs?.docs.map(doc => doc.data() as T) || [];
}

export const userQueries = (firestore: Firestore.Firestore, userId?: string) => {
  const prefix = userId ? `users/${userId}` : 'users/placeholder';

  return {
    userDoc: Firestore.doc(firestore, prefix),
    chunksRef: Firestore.collection(firestore, prefix, 'chunks').withConverter(
      chunksConverter
    ),
    chunkById: (id: string) => Firestore.doc(firestore, prefix, 'chunks', id).withConverter(
      chunksConverter
    ),
    categoriesRef: Firestore.collection(firestore, prefix, 'categories').withConverter(
      categoryConverter
    ),
    currentTaskRef: Firestore.doc(firestore, prefix, 'tasks', 'currentTask').withConverter(
      currentTaskConverter
    ),
  } as const;
};

export const userQueriesLoggedIn = (firestore: Firestore.Firestore, user: User) => userQueries(firestore, user.uid);
