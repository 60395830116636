import './index.css';
import React from 'react';
import {render} from 'react-dom';
import {setupIonicReact} from '@ionic/react';
import * as serviceWorker from './serviceWorker';
import App from './App';

setupIonicReact({
  mode: 'md',
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const container = document.getElementById('root');

render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  container
);
