import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';
import humanizeDuration from 'humanize-duration';

dayjs.extend(durationPlugin);

type Duration = durationPlugin.Duration;

export function humanize(duration: Duration) {
  const ms = duration.asMilliseconds();
  if (duration.asMinutes() >= 60) {
    return humanizeDuration(ms, {maxDecimalPoints: 1, units: ['h']});
  }
  return humanizeDuration(ms, {maxDecimalPoints: 0, units: ['m']});
}
