import './index.css';
import {FirebaseOptions} from 'firebase/app';

export const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyCpiSRkc3ix_i7ABo4C4IjLBoH5g1uuf3I',
  authDomain: 'time-based-goals-app.firebaseapp.com',
  projectId: 'time-based-goals-app',
  storageBucket: 'time-based-goals-app.appspot.com',
  messagingSenderId: '777737486514',
  appId: '1:777737486514:web:0083703593cd8efe0249ab',
  measurementId: 'G-BMZ6L373PE',
};
