import * as Icons from '@heroicons/react/solid';
import dayjs from 'dayjs';

import * as React from 'react';
import {useEffect, useState} from 'react';

import * as Array from 'fp-ts/es6/Array';

import * as Firestore from 'firebase/firestore';

import {TextField, TextFieldProps} from '@mui/material';
import {
  useFirestoreCollectionMutation,
  useFirestoreDocumentMutation,
} from '@react-query-firebase/firestore';

import {useFirestore, useUser} from 'reactfire';
import {humanize} from '../util';
import {Category, Chunk, CurrentTask, userQueriesLoggedIn} from './queries';

import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';

function useInterval(callback: () => void, delay: number) {
  const savedCallback = React.useRef(() => {});

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

interface Ref<T> {
  ref: T;
}

interface TaskProps {
  taskRef: Ref<CurrentTask>;
  chunks: Chunk[];
  categoriesMRU: Category[];
}

function timeSinceTaskStart(task: CurrentTask) {
  return dayjs.duration(dayjs().diff(dayjs(task.inTime)));
}

const CurrentActivity: React.FC<{task: CurrentTask} & React.HTMLAttributes<HTMLDivElement>> = ({
  task,
  ...rest
}) => {
  const elapsedTime = timeSinceTaskStart(task);
  const [counter, setCounter] = useState(0);
  useInterval(() => setCounter(counter + 1), 1000);
  const isToday = task.inTime.isBefore(dayjs().startOf('day'));

  return (
    <div {...rest}>
      <div>Started: {task.inTime.format(isToday ? 'h:mma' : 'ddd M/DD h:mma')}</div>
      <div>
        Elapsed Time:
        {humanize(elapsedTime)}
      </div>
    </div>
  );
};

// Use strings so props are not constantly re-rendered
const PickTime: React.FC<{
  min: dayjs.Dayjs;
  max: dayjs.Dayjs;
  onSelectTime: (timePicked: dayjs.Dayjs) => void;
  value?: dayjs.Dayjs;
  disabled?: boolean;
}> = function({min, max, onSelectTime, disabled, value}) {
  const [open, setOpen] = useState(false);
  const [valueState, setValueState] = useState<dayjs.Dayjs | null | undefined>(
    /* Force the placeholder */
    value || null
  );

  const [refresh, setRefresh] = useState(0);

  /* Work around min/max being broken if placeholder is active */
  const drivenVal = open && !valueState ? undefined : valueState;

  /* If upstream state got cleared, reset internal state */
  const prevValue = React.useRef<dayjs.Dayjs>();

  useEffect(() => {
    if (prevValue && !value) {
      setValueState(null);
      setRefresh(r => r + 1);
    }
    prevValue.current = value;
  }, [value]);

  const minTime = min.isAfter(dayjs().startOf('day')) ? min : dayjs().startOf('day');

  const today = !value?.isBefore(dayjs().startOf('day'));

  const maxTime = today ? max : value?.endOf('day') || max;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        key={refresh}
        renderInput={({inputProps, ...params}: TextFieldProps) => (
          <TextField
            {...params}
            onClick={() => setOpen(true)}
            style={{width: '16rem'}}
            helperText=""
            inputProps={{...inputProps, placeholder: 'Now'}}
          />
        )}
        inputFormat={today ? 'hh:mm a' : 'dddd hh:mm a'}
        disabled={disabled}
        open={open}
        /* Weirdly, null gives the placeholder, undefined gives an actual time */
        // TODO: picked time is not showing up
        value={drivenVal}
        onChange={(val: dayjs.Dayjs | null) => {
          setValueState(val);
          onSelectTime(val || dayjs());
        }}
        onAccept={() => {
          setOpen(false);
        }}
        onClose={() => {
          /* Reset back to null if nothing changed */
          setOpen(false);
          if (!valueState) {
            setValueState(null);
          }
        }}
        openTo="hours"
        minutesStep={5}
        minTime={minTime}
        maxTime={maxTime}
      />
    </LocalizationProvider>
  );
};

export const TaskControl: React.FC<TaskProps> = ({taskRef, chunks, categoriesMRU}) => {
  const queries = userQueriesLoggedIn(useFirestore(), useUser().data!);
  const addChunk = useFirestoreCollectionMutation(queries.chunksRef);
  const editTopChunk = useFirestoreDocumentMutation(queries.chunkById(chunks[0].id!));
  const setCurrentTask = useFirestoreDocumentMutation(queries.currentTaskRef);
  const addCategory = useFirestoreCollectionMutation(queries.categoriesRef);
  const task = taskRef.ref;
  const [taskName, setTaskName] = useState(task.name);

  const newCategory: Category = {category: 'New Category'};
  const [categorySelection, setCategorySelection] = useState<Category>(
    task.category || newCategory
  );
  const [categoryInput, setCategoryInput] = useState<Category>();

  const isNewCategory = categorySelection.category === newCategory.category;

  function done() {
    function saveCategory(category: Category) {
      addCategory.mutate(category);
    }
    function saveTask(outTime: dayjs.Dayjs) {
      const chunkNew = {
        ...task,
        name: taskName,
        inTime: Firestore.Timestamp.fromDate(task.inTime.toDate()),
        outTime: Firestore.Timestamp.fromDate(outTime.toDate()),
      };
      addChunk.mutate(chunkNew);
    }

    if (isNewCategory && categoryInput) {
      saveCategory(categoryInput);
    }

    /* If outTime was set, use it, or it's 'now' */
    const outTime = task.outTime ? task.outTime : dayjs();
    saveTask(outTime);

    /* Updates firestore and reinits UI state */
    setCurrentTask.mutate({
      /* Same category */
      category: task.category,
      inTime: outTime,
      outTime: undefined,
      name: '',
    });
    setTaskName('');
  }

  function extendTop() {
    function saveTask(outTime: dayjs.Dayjs) {
      editTopChunk.mutate({
        ...chunks[0],
        outTime: Firestore.Timestamp.fromDate(outTime.toDate()),
      });
    }

    /* If outTime was set, use it, or it's 'now' */
    const outTime = task.outTime ? task.outTime : dayjs();
    saveTask(outTime);

    /* Updates firestore and reinits UI state */
    setCurrentTask.mutate({
      /* Same category */
      category: task.category,
      inTime: outTime,
      outTime: undefined,
      name: '',
    });
  }

  const selections = Array.cons(
    <option key={newCategory.category} value={newCategory.category}>
      {newCategory.category}
    </option>
  )(
    categoriesMRU
      .filter(c => c.category !== newCategory.category)
      .map(c => <option key={c.category}>{c.category}</option>)
  );

  /* There's a problem here where we're mixing state  */
  const onSelectTime = (m: dayjs.Dayjs) => {
    taskRef.ref = {...task, outTime: m};
    setCurrentTask.mutate(task);
  };

  return (
    <div className="mx-auto card bg-base-100 shadow-xl ">
      <div className="card-body p-4">
        <div className="flex flex-row space-x-12">
          <div className="flex flex-col space-y-4 w-1/2">
            <div>
              <div className="font-bold ml-2">Current Activity</div>
              <CurrentActivity className="" task={task} />
            </div>
            <input
              type="text"
              placeholder="Enter Task Name"
              className="input input-bordered w-full"
              value={taskName}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  done();
                }
              }}
              onChange={e => {
                setTaskName(e.target.value);
              }}
            />
            <div className="flex flex-row">
              <div className="space-y-2 flex-row w-full">
                <div className="flex font-bold ml-2 space-x-4">Finish Time</div>
                <div className="flex flex-row justify-between flex-wrap">
                  <div className="btn-group p-1 flex-nowrap">
                    <button
                      className="btn btn-secondary"
                      disabled={task.inTime.startOf('day').isSame(task.outTime?.startOf('day'))}
                      onClick={() =>
                        onSelectTime((task.outTime || dayjs()).subtract(1, 'day').endOf('day'))
                      }
                    >
                      <Icons.RewindIcon className="h-5 w-5" />
                      <div className="ml-1">Day</div>
                    </button>
                    <button
                      className="btn btn-accent"
                      onClick={() =>
                        setCurrentTask.mutate({
                          ...task,
                          inTime: chunks[0]?.outTime || dayjs(),
                          outTime: undefined,
                        })
                      }
                    >
                      {/* TODO: what does this even do? */}
                      Reset
                    </button>
                    <button className="btn btn-info" onClick={() => extendTop()}>
                      Extend Last
                    </button>
                  </div>
                  <div className="flex flex-row">
                    <PickTime
                      min={task.inTime}
                      max={dayjs()}
                      onSelectTime={onSelectTime}
                      disabled={dayjs().isBefore(task.inTime.add(15, 'minute'))}
                      /* Control with task value if set */
                      value={task.outTime}
                    />
                    <div className="btn-group p-1">
                      <button
                        className="btn btn-primary"
                        disabled={(isNewCategory && !categoryInput?.category) || !taskName}
                        onClick={() => done()}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-1/2 space-y-4">
            <div className="space-y-2">
              <div className="font-bold ml-2">Category</div>
              <div className="flex flex-col space-y-2">
                <select
                  className="select select-bordered w-full"
                  value={categorySelection.category}
                  placeholder="Select Category"
                  onChange={e => {
                    const selectedCategory = {
                      category: e.target.value,
                    };
                    setCategorySelection(selectedCategory);
                    setCategoryInput(undefined);
                    setCurrentTask.mutate({
                      ...task,
                      category: selectedCategory,
                    });
                  }}
                >
                  {selections}
                </select>
                {isNewCategory ? (
                  <input
                    type="text"
                    placeholder="Enter New Category"
                    className="input input-bordered w-full"
                    onChange={e => {
                      setCategoryInput({category: e.target.value});
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
