import {Redirect, Route, Switch} from 'react-router';

import '@ionic/core/css/core.css';
import '@ionic/core/css/ionic.bundle.css';
import {
  IonApp,
  IonContent,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import * as React from 'react';

import * as Icons from '@heroicons/react/solid';

import {useAuthSignOut} from '@react-query-firebase/auth';
import {getAuth} from 'firebase/auth';
import {QueryClient, QueryClientProvider, useQueryClient} from 'react-query';

import {initializeFirestore} from 'firebase/firestore';
import {
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  useFirebaseApp,
  useInitFirestore,
  useUser,
} from 'reactfire';
import {firebaseConfig} from './Firebase';
import Landing from './Landing';
import Tasks from './tasks/Tasks';

const Logout: React.FC = function() {
  const auth = getAuth(useFirebaseApp());
  const user = useUser();
  const logoutMutation = useAuthSignOut(auth);
  const loggedIn = user && user.data?.uid;
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (loggedIn) {
      logoutMutation.mutateAsync().then(() => queryClient.invalidateQueries({queryKey: ['user']}));
    }
  }, [loggedIn, logoutMutation, queryClient]);
  return <Redirect to="/" />;
};

const LoggedinRoutes: React.FC = function() {
  return (
    <IonPage>
      <IonTabs>
        {/* IonTabs must contain IonRouterOutlet */}
        <IonRouterOutlet>
          <Redirect exact path="/tabs" to="/tabs/track" />
          <Route
            path="/tabs/track"
            render={() => (
              <IonContent>
                <Tasks />
              </IonContent>
            )}
            exact
          />
          <Route path="/tabs/analyze" render={() => 'WAT'} exact />
          <Route path="/tabs/logout" component={Logout} exact />
        </IonRouterOutlet>

        <IonTabBar slot="top">
          <IonTabButton tab="track" href="/tabs/track">
            <Icons.ViewListIcon className="h-5 w-5" />
            <IonLabel>Track</IonLabel>
          </IonTabButton>
          <IonTabButton tab="analyze" href="/tabs/analyze">
            <Icons.ChartBarIcon className="h-5 w-5" />
            <IonLabel>Analyze</IonLabel>
          </IonTabButton>
          <IonTabButton tab="logout" href="/tabs/logout">
            <Icons.LogoutIcon className="h-5 w-5" />
            <IonLabel>Logout</IonLabel>
          </IonTabButton>
          )
        </IonTabBar>
      </IonTabs>
    </IonPage>
  );
};

const Root: React.FC = function() {
  const user = useUser();
  const loggedIn = user && user.data?.uid;

  return (
    <IonApp>
      <IonPage>
        <IonReactRouter>
          <Switch>
            <Route
              path="/login"
              render={() =>
                loggedIn ? (
                  <Redirect to="/tabs" />
                ) : (
                  <IonContent>
                    <Landing />
                  </IonContent>
                )
              }
            />
            <Route
              path="/"
              render={() => (loggedIn ? <LoggedinRoutes /> : <Redirect exact to="/login" />)}
            />
          </Switch>
        </IonReactRouter>
      </IonPage>
    </IonApp>
  );
};

const FirebaseApp: React.FC = function() {
  const {status, data: firestore} = useInitFirestore(async firebaseApp => {
    const db = initializeFirestore(firebaseApp, {ignoreUndefinedProperties: true});
    // Offline persistence
    // await enableIndexedDbPersistence(db)
    return db;
  });
  const firebaseApp = useFirebaseApp();
  const auth = getAuth(firebaseApp);

  // firestore init isn't complete yet
  if (status === 'loading') {
    return <div />;
  } else {
    return (
      <AuthProvider sdk={auth}>
        <FirestoreProvider sdk={firestore}>
          <Root />
        </FirestoreProvider>
      </AuthProvider>
    );
  }
};

const queryClient = new QueryClient();

const App: React.FC = function() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <QueryClientProvider client={queryClient}>
        <FirebaseApp />
      </QueryClientProvider>
    </FirebaseAppProvider>
  );
};

export default App;
