import {useAuthSignInWithPopup} from '@react-query-firebase/auth';
import {GoogleAuthProvider, getAuth} from 'firebase/auth';
import {useQueryClient} from 'react-query';
import {LoginIcon} from '@heroicons/react/solid';
import {useFirebaseApp} from 'reactfire';

function Login() {
  const auth = getAuth(useFirebaseApp());
  const mutation = useAuthSignInWithPopup(auth, {});

  const queryClient = useQueryClient();

  return (
    <button
      className="btn btn-primary gap-2"
      disabled={mutation.isLoading}
      onClick={() => {
        mutation
          .mutateAsync({
            provider: new GoogleAuthProvider(),
          })
          .then(() => queryClient.invalidateQueries({queryKey: ['user']}));
      }}
    >
      <LoginIcon className="h-5 w-5" />
      Login
    </button>
  );
}

export default function Landing() {
  return (
    <div className="hero min-h-screen ">
      <svg
        id="svgSurface"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        style={{width: '800px', height: '450px'}}
      >
        <rect x="0" y="0" width="800" height="450" fill="white" />
        <path
          d="M167.82 54.68C201.78 108.64 201.78 108.64 230.27 54.68C258.76 0.73 258.76 0.73 196.31 0.73C133.85 0.73 133.85 0.73 167.82 54.68"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M211.48 128.16C219.23 146.8 219.23 146.8 259.41 148.39C299.59 149.98 299.59 149.98 344.84 111.89C390.08 73.79 390.08 73.79 394.64 37.7C399.19 1.61 399.19 1.61 329.95 1.61C260.7 1.61 260.7 1.61 232.21 55.56C203.73 109.51 203.73 109.51 211.48 128.16"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M414.17 91.73C434 110.21 434 110.21 494.96 116.01C555.93 121.81 555.93 121.81 593.28 67.88C630.63 13.96 630.63 13.96 631.68 7.51C632.73 1.07 632.73 1.07 518.09 1.07C403.45 1.07 403.45 1.07 398.9 37.16C394.35 73.25 394.35 73.25 414.17 91.73"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M80.7 170.13C159.03 194.26 159.03 194.26 187.36 170.73C215.69 147.2 215.69 147.2 207.94 128.55C200.19 109.91 200.19 109.91 166.23 55.95C132.26 2 132.26 2 67.32 2C2.37 2 2.37 2 2.37 73.99C2.37 145.99 2.37 145.99 80.7 170.13"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M656.52 97.1C678.38 179.92 678.38 179.92 738.28 166.13C798.18 152.33 798.18 152.33 798.18 76.86C798.18 1.39 798.18 1.39 717.46 1.39C636.75 1.39 636.75 1.39 635.7 7.84C634.65 14.28 634.65 14.28 656.52 97.1"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M586.17 189.11C614.17 254.61 614.17 254.61 645.39 218C676.61 181.4 676.61 181.4 654.74 98.58C632.87 15.76 632.87 15.76 595.52 69.69C558.17 123.61 558.17 123.61 586.17 189.11"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M452.1 158.67C469.57 203.66 469.57 203.66 523.4 271.3C577.23 338.94 577.23 338.94 583.87 330.49C590.5 322.05 590.5 322.05 601.53 289.16C612.57 256.26 612.57 256.26 584.56 190.76C556.56 125.27 556.56 125.27 495.6 119.47C434.63 113.68 434.63 113.68 452.1 158.67"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M309.86 181.38C318.58 210.84 318.58 210.84 327.51 223.94C336.45 237.04 336.45 237.04 349.88 245.25C363.32 253.47 363.32 253.47 414.77 228.08C466.21 202.69 466.21 202.69 448.75 157.69C431.28 112.7 431.28 112.7 411.46 94.22C391.63 75.74 391.63 75.74 346.38 113.83C301.13 151.93 301.13 151.93 309.86 181.38"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M695.78 304.43C797.93 286.52 797.93 286.52 797.93 221.13C797.93 155.75 797.93 155.75 738.03 169.55C678.13 183.34 678.13 183.34 646.91 219.95C615.7 256.55 615.7 256.55 604.66 289.44C593.63 322.33 593.63 322.33 695.78 304.43"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M373.76 266.55C382.34 277.39 382.34 277.39 406.75 290.85C431.17 304.32 431.17 304.32 501.23 329.54C571.3 354.76 571.3 354.76 573.52 347.48C575.74 340.2 575.74 340.2 521.91 272.56C468.07 204.92 468.07 204.92 416.62 230.31C365.18 255.7 365.18 255.7 373.76 266.55"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M76.21 333C150.86 349.02 150.86 349.02 154.54 273.17C158.22 197.32 158.22 197.32 79.89 173.18C1.56 149.05 1.56 149.05 1.56 233.01C1.56 316.98 1.56 316.98 76.21 333"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M163.2 363.12C172.82 377.06 172.82 377.06 176.49 381.84C180.15 386.61 180.15 386.61 247.78 299.56C315.41 212.5 315.41 212.5 306.68 183.05C297.96 153.59 297.96 153.59 257.78 152C217.6 150.41 217.6 150.41 189.27 173.94C160.94 197.48 160.94 197.48 157.26 273.33C153.58 349.18 153.58 349.18 163.2 363.12"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M193.33 412.38C205.68 436.31 205.68 436.31 208.44 440.86C211.19 445.42 211.19 445.42 248.76 419.03C286.32 392.65 286.32 392.65 310.22 316.59C334.11 240.54 334.11 240.54 325.18 227.44C316.24 214.34 316.24 214.34 248.62 301.4C180.99 388.45 180.99 388.45 193.33 412.38"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M305.92 385.95C323.86 379.98 323.86 379.98 332.51 368.38C341.16 356.77 341.16 356.77 360.48 317.35C379.79 277.94 379.79 277.94 371.21 267.1C362.63 256.25 362.63 256.25 349.2 248.04C335.77 239.82 335.77 239.82 311.87 315.88C287.98 391.93 287.98 391.93 305.92 385.95"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M385.8 330.74C429.54 304.79 429.54 304.79 405.12 291.33C380.71 277.86 380.71 277.86 361.39 317.27C342.07 356.69 342.07 356.69 385.8 330.74"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M447.95 385.84C569.84 390.87 569.84 390.87 570.4 373.5C570.95 356.13 570.95 356.13 500.89 330.91C430.82 305.69 430.82 305.69 387.08 331.64C343.35 357.59 343.35 357.59 334.7 369.2C326.06 380.8 326.06 380.8 447.95 385.84"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M579.35 419.78C584.97 448.42 584.97 448.42 690.91 448.42C796.85 448.42 796.85 448.42 796.85 368.78C796.85 289.13 796.85 289.13 694.7 307.04C592.55 324.95 592.55 324.95 585.92 333.39C579.29 341.83 579.29 341.83 577.06 349.12C574.84 356.4 574.84 356.4 574.29 373.77C573.73 391.13 573.73 391.13 579.35 419.78"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M85.89 400.61C170.16 379.2 170.16 379.2 160.54 365.26C150.92 351.32 150.92 351.32 76.27 335.3C1.62 319.28 1.62 319.28 1.62 370.64C1.62 422.01 1.62 422.01 85.89 400.61"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M102.5 434.28C202.79 437.49 202.79 437.49 190.44 413.56C178.1 389.64 178.1 389.64 174.43 384.86C170.76 380.09 170.76 380.09 86.49 401.49C2.22 422.9 2.22 422.9 2.22 426.99C2.22 431.07 2.22 431.07 102.5 434.28"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M396.42 448.72C579.41 449.37 579.41 449.37 573.79 420.73C568.17 392.08 568.17 392.08 446.28 387.05C324.38 382.02 324.38 382.02 306.44 387.99C288.5 393.97 288.5 393.97 250.96 421.01C213.43 448.06 213.43 448.06 396.42 448.72"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
        <path
          d="M205.86 443.33C203.07 438.11 203.07 438.11 102.78 434.9C2.5 431.7 2.5 431.7 2.5 440.78C2.5 449.86 2.5 449.86 105.57 449.2C208.64 448.55 208.64 448.55 205.86 443.33"
          fill="#efefef"
          stroke="3f3f3f"
          strokeWidth="1"
        />
      </svg>
      <div className="hero-content text-center">
        <div className="max-w-md">
          <h1 className="text-5xl font-bold">TimeSplit</h1>
          <p className="py-6 text-xl">Take control of your time.</p>
          <p className="py-8 text-l">Spend it better by knowing how it's really spent.</p>
          <Login />
        </div>
      </div>
    </div>
  );
}
