import dayjs from 'dayjs';
import * as Icons from '@heroicons/react/solid';
import * as React from 'react';

import {useFirestoreDocumentDeletion} from '@react-query-firebase/firestore';

import {useEffect, useState} from 'react';
import {TaskControl} from './TaskControl';
import {humanize} from '../util';

import {Category, Chunk, CurrentTask, userQueriesLoggedIn} from './queries';
import {useFirestore, useFirestoreDocData, useFirestoreCollectionData, useUser} from 'reactfire';
import * as Firestore from 'firebase/firestore';
import {motion} from 'framer-motion';
const defaultCategories: Category[] = ['Idle', 'Coding', 'Planning', 'Playing', 'Piano'].map(c => ({
  category: c,
}));

const ChunksRow: React.FC<{
  chunk: Chunk;
  idx: number;
  daySplit: boolean;
}> = ({chunk: c, idx, daySplit: daySplit}) => {
  const deleteRow = useFirestoreDocumentDeletion(
    userQueriesLoggedIn(useFirestore(), useUser().data!).chunkById(c.id!)
  );

  return (
    <tr>
      <td />
      <td>{c.category?.category}</td>
      <td>
        <div className="w-48 text-ellipsis overflow-clip">{c.name}</div>
      </td>
      <td>{dayjs(c.outTime).format(daySplit ? 'ddd M/DD h:mma' : 'h:mma')}</td>
      <td>{humanize(dayjs.duration(dayjs(c.outTime).diff(dayjs(c.inTime))))}</td>
      <td>
        {idx === 0 ? (
          <button
            className="btn btn-secondary btn-xs"
            onClick={() => {
              deleteRow.mutateAsync();
            }}
          >
            <Icons.TrashIcon className="h-5 w-5" />
          </button>
        ) : null}
      </td>
    </tr>
  );
};

const defaultTask = (): CurrentTask => {
  const initialTime = dayjs();
  return {
    category: {category: 'Idle'},
    inTime: initialTime,
    outTime: undefined,
    name: '',
  };
};

const ChunksTable: React.FC<{chunks: Chunk[]}> = ({chunks}) => (
  <div className="mx-auto card bg-base-100 shadow-xl overflow-auto">
    <div className="card-body p-4">
      <table className="table w-full">
        <thead>
          <tr>
            <th>{/* placeholder for correct spacing */}</th>
            <th>Category</th>
            <th>Name</th>
            <th>Finished</th>
            <th>Duration</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {chunks.map((c, idx) => {
            const daySplit =
              chunks.length > idx + 1 &&
              idx > 0 &&
              (c.outTime.startOf('day').isBefore(chunks[idx - 1].outTime.startOf('day')) ||
                c.outTime.startOf('day').isAfter(chunks[idx + 1].outTime.startOf('day')));
            return <ChunksRow daySplit={daySplit} idx={idx} key={c.id!} chunk={c} />;
          })}
        </tbody>
      </table>
    </div>
  </div>
);

const Wrapper: React.FC = () => {
  const user = useUser();

  const queries = userQueriesLoggedIn(useFirestore(), user.data!);
  const task = useFirestoreDocData(queries.currentTaskRef, {initialData: defaultTask()});

  const chunks = useFirestoreCollectionData(
    Firestore.query(queries.chunksRef, Firestore.orderBy('outTime', 'desc'), Firestore.limit(25))
  );

  const categoriesMRU = useFirestoreCollectionData(
    Firestore.query(queries.categoriesRef, Firestore.orderBy('category')),
    {initialData: defaultCategories}
  );

  const progress =
    0 +
    (user.data ? 25 : 0) +
    (task.data ? 25 : 0) +
    (chunks.data ? 25 : 0) +
    (categoriesMRU.data ? 25 : 0);

  const [wait, setWait] = useState(true);

  useEffect(() => {
    if (progress >= 100 && wait) {
      setTimeout(() => {
        setWait(false);
      }, 500);
    }
  }, [progress, wait]);

  if (user.data && task.data && chunks.data && categoriesMRU.data) {
    return (
      <div className="p-4 space-y-4" data-theme="emerald">
        <TaskControl
          taskRef={{ref: task.data}}
          chunks={chunks.data}
          categoriesMRU={categoriesMRU.data}
        />
        <ChunksTable chunks={chunks.data} />
      </div>
    );
  }
  return (
    <div className="flex place-content-center h-full">
      <motion.div
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        initial={{'--value': 0} as any}
        className="radial-progress place-self-center"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        animate={{'--value': progress} as any}
      >
        {progress >= 100 ? 'Loaded' : 'Loading'}
      </motion.div>
    </div>
  );
};

export default Wrapper;
